import React, { Fragment, useRef } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, Link } from 'gatsby'
import Swiper from 'react-id-swiper'
import { FormattedMessage, injectIntl, useIntl } from 'gatsby-plugin-react-intl'
import Pagina from '../components/Pagina'
import parse from 'html-react-parser'
import ReactPlayer from 'react-player'

import grafismo from '../images/Home/Grafismo.svg'
import setaCarousel from '../images/Home/Carousel/seta-carrossel.svg'
import integridade from '../images/Home/Valores/integridade.svg'
import inovacao from '../images/Home/Valores/inovacao.svg'
import solidez from '../images/Home/Valores/solidez.svg'
import simplicidade from '../images/Home/Valores/simplicidade.svg'
import empreendedorismo from '../images/Home/Valores/empreendedorismo.svg'
import valorizacao from '../images/Home/Valores/valorizacao.svg'
import sustentabilidade from '../images/Home/Valores/sustentabilidade.svg'
import right from '../images/Home/Valores/seta-direita.svg'
import left from '../images/Home/Valores/seta-esquerda.svg'
import play from '../images/Home/NovaIdentidade/play.png'

import video from '../videos/Brand.mp4'

import './styles/index.scss'

const Banner = ({ images }) => {
  const params = {
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true
    // },
    // loop: true,
    effect: 'fade',
    autoplay: {
      delay: 5000
    },
    slidesPerView: 1,
    spaceBetween: 10,
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 480px
      480: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      // when window width is >= 640px
      1023: {
        slidesPerView: 1,
        spaceBetween: 5
      }
    }
  }

  return (
    <div id="swiperBanner">
      <Swiper {...params}>
        {images.edges.map((img, index) => (
          <div key={index}>
            <GatsbyImage image={img.node.banner.localFile.childImageSharp.gatsbyImageData} alt={`Banner Lwart ${index}`} className="banner" />
            <div className="aboveBanner d-flex">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    {img.node.texto &&
                      <div className="col-12 p-0 d-flex justify-content-start ml-lg-0 ml-4 pl-lg-0 pl-1">
                        <div className="p-0 col-lg-12 col-8">
                          <h1 style={{ color: img.node.corTexto }}>{img.node.texto}</h1>
                        </div>
                      </div>
                    }
                    {img.node.textoDoBotao &&
                      <div className="col-lg-12 col-12 p-0 d-flex justify-content-start ml-lg-0 ml-4 pl-lg-0 pl-1">
                        <Link to={img.node.linkBotao}>
                          <button className="btn btn-blue text-white mt-4">{img.node.textoDoBotao}</button>
                        </Link>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Swiper>
    </div>
  )
}

const GrupoLwart = () => {
  const intl = useIntl()
  return (
    <div className="container-fluid m-0 p-0" id="SectionGrupoLwart">
      <div className="grafismoDiv">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <h1 className='text-blue-light'><FormattedMessage id="home.sectiongrupolwart.titulo" /></h1>
              <div className="col-lg-9 p-0 pb-2 text-gray">
                <p>{parse(intl.formatMessage({ id: 'home.sectiongrupolwart.paragrafo1' }))}</p>

                <p>{parse(intl.formatMessage({ id: 'home.sectiongrupolwart.paragrafo2' }))}</p>

                <p>{parse(intl.formatMessage({ id: 'home.sectiongrupolwart.paragrafo3' }))}</p>

                <p>{parse(intl.formatMessage({ id: 'home.sectiongrupolwart.paragrafo4' }))}</p>

                <p><Link to='/historia/' className='text-gray hover-none'>{parse(intl.formatMessage({ id: 'home.sectiongrupolwart.link' }))}</Link></p>
              </div>
            </div>
            <div className="col-auto grafismoGrupo p-0 d-lg-block d-none">
              <img src={grafismo} alt="Icone Grafismo" className="w-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Carousel = ({ images }) => {
  const Molde = ({ image, color, title, link }) => {
    return (
      <Link to={link} className='custom-hover'>
        <div className="d-lg-flex" id='carousel'>
          <div className="h-100 width-custom">
            <GatsbyImage image={image} alt='Imagem Lwart' className="height-custom" />
          </div>
          <div style={{ backgroundColor: color }} className="h-lg-auto card-Carousel width-custom">
            <div className="d-flex align-items-lg-end align-items-center p-lg-5 p-4 h-lg-100">
              <div>
                <h2>{parse(title)}</h2>
                <p className="mt-4">{intl.formatMessage({ id: 'home.sectioncarousel.conheca' })} <img src={setaCarousel} alt="Seta do Carousel" className="ml-3" /></p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    )
  }
  const { carousel1, carousel2, carousel3 } = images

  const ref = useRef(null)

  const goNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slidePrev()
    }
  }

  const params = {
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 480px
      480: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      // when window width is >= 640px
      1023: {
        slidesPerView: 2,
        spaceBetween: 0
      }
    }
  }
  const intl = useIntl()
  return (
    <div className='position-relative' id="swiperCarousel">
      <Swiper {...params} ref={ref}>
        <div>
          <Molde image={carousel1.childImageSharp.gatsbyImageData} color="#396C8B" link="/governanca/" title={intl.formatMessage({ id: 'home.sectioncarousel.item1' })} />
        </div>
        <div>
          <Molde image={carousel2.childImageSharp.gatsbyImageData} color="#199DDD" link="/historia/" title={intl.formatMessage({ id: 'home.sectioncarousel.item2' })} />
        </div>
        <div>
          <Molde image={carousel3.childImageSharp.gatsbyImageData} color="#95CB47" link="/negocios/" title={intl.formatMessage({ id: 'home.sectioncarousel.item3' })} />
        </div>
      </Swiper>
      <div className='swiper-button-next h-100 d-lg-block d-none' onMouseEnter={goNext}></div>
      <div className='swiper-button-prev h-100 d-lg-block d-none' onMouseEnter={goPrev}></div>
    </div>
  )
}

const NovaIdentidade = ({ image }) => {
  const intl = useIntl()
  return (
    <div className="container-fluid px-0" id="SectionNovaIdentidade">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h2>{parse(intl.formatMessage({ id: 'home.sectionnovaidentidade.titulo' }))}</h2>
            <div className="col-lg-10 p-0">
              <p>{parse(intl.formatMessage({ id: 'home.sectionnovaidentidade.texto' }))}</p>
            </div>
          </div>
          <div className="col-lg-6 pt-lg-0 pt-5">
            <ReactPlayer controls playing light={play} url={video} className='videoMobile' />
          </div>
        </div>
      </div>
    </div>
  )
}

const NossosValores = () => {
  const Molde = ({ icon, title, text }) => {
    return (
      <div>
        <div className="col-12">
          <div className="mb-lg-0 mb-5 mx-1">
            <div className='d-flex justify-content-center'>
              <img src={icon} alt="Inovacao" width="80" height="80" />
            </div>
            <h3 className='text-center'>{title}</h3>
            <p className='text-center'>{text}</p>
          </div>
        </div>
      </div>
    )
  }

  const ref = useRef(null)

  const goNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slidePrev()
    }
  }

  const params = {
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev'
    // },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    slidesPerView: 1,
    spaceBetween: 0,
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 480px
      480: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      // when window width is >= 640px
      1023: {
        slidesPerView: 3,
        spaceBetween: 0
      }
    }
  }
  const intl = useIntl()
  return (
    <div className="container-fluid px-0 position-relative" id="SectionNossosValores">
      <div className="container">
        <div className="d-flex justify-content-start">
          <h2>{intl.formatMessage({ id: 'home.sectionnossosvalores.titulo' })}</h2>
        </div>
        <Swiper {...params} ref={ref}>
          <div>
            <Molde icon={integridade} title={intl.formatMessage({ id: 'home.sectionnossosvalores.integridade.titulo' })} text={intl.formatMessage({ id: 'home.sectionnossosvalores.integridade.texto' })} />
          </div>
          <div>
            <Molde icon={solidez} title={intl.formatMessage({ id: 'home.sectionnossosvalores.solidez.titulo' })} text={intl.formatMessage({ id: 'home.sectionnossosvalores.solidez.texto' })} />
          </div>
          <div>
            <Molde icon={inovacao} title={intl.formatMessage({ id: 'home.sectionnossosvalores.inovacao.titulo' })} text={intl.formatMessage({ id: 'home.sectionnossosvalores.inovacao.texto' })} />
          </div>
          <div>
            <Molde icon={simplicidade} title={intl.formatMessage({ id: 'home.sectionnossosvalores.simplicidade.titulo' })} text={intl.formatMessage({ id: 'home.sectionnossosvalores.simplicidade.texto' })} />
          </div>
          <div>
            <Molde icon={empreendedorismo} title={intl.formatMessage({ id: 'home.sectionnossosvalores.empreendedorismo.titulo' })} text={intl.formatMessage({ id: 'home.sectionnossosvalores.empreendedorismo.texto' })} />
          </div>
          <div>
            <Molde icon={valorizacao} title={intl.formatMessage({ id: 'home.sectionnossosvalores.valorizacao.titulo' })} text={intl.formatMessage({ id: 'home.sectionnossosvalores.valorizacao.texto' })} />
          </div>
          <div>
            <Molde icon={sustentabilidade} title={intl.formatMessage({ id: 'home.sectionnossosvalores.sustentabilidade.titulo' })} text={intl.formatMessage({ id: 'home.sectionnossosvalores.sustentabilidade.texto' })} />
          </div>
        </Swiper>
        <div className='swiper-button-next h-60 d-lg-block d-none' onMouseEnter={goNext}></div>
        <div className='swiper-button-prev h-60 d-lg-block d-none' onMouseEnter={goPrev}></div>
        <div className='d-lg-flex d-none justify-content-center pt-3'>
          <div className='d-flex align-items-center navigation'>
            <img src={left} />
            <p className='mx-3 text-uppercase'>{intl.formatMessage({ id: 'home.sectionnossosvalores.navigacao' })}</p>
            <img src={right} />
          </div>
        </div>
      </div>
    </div>
  )
}

const ResponsabilidadeSocial = ({ image }) => {
  const intl = useIntl()
  return (
    <div className="container-fluid" id="SectionResponsabilidadeSocial">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 pr-lg-4">
            <GatsbyImage
              image={image}
              alt="Imagem de 2 crianças sorrindo" />
          </div>
          <div className="col-lg-6 pl-lg-4">
            <h1 className="mt-lg-0 mt-3 text-green">{intl.formatMessage({ id: 'home.sectionresponsabilidadesocial.titulo' })}</h1>
            <p>{parse(intl.formatMessage({ id: 'home.sectionresponsabilidadesocial.subtitulo' }))}</p>
            <ul className="pl-4 p-0">
              {parse(intl.formatMessage({ id: 'home.sectionresponsabilidadesocial.lista' }))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

const IndexPage = ({ data }) => {
  return (
    <Fragment>
      <Pagina pagina={data.pagina} />
      <Banner images={data.banner} />
      <GrupoLwart />
      <Carousel images={data} />
      <NovaIdentidade image={data.imageSustentavel.childImageSharp.gatsbyImageData} />
      <NossosValores />
      <ResponsabilidadeSocial image={data.imageResponsabilidade.childImageSharp.gatsbyImageData} />
    </Fragment>
  )
}

export default injectIntl(IndexPage)

export const homeQuery = graphql`
  query ContentfulHome($locale: String){
    pagina: contentfulPagina(path: {eq: "/"}, node_locale: {regex: $locale}) {
      contentful_id
      node_locale
      metaTitle
      metaDescription
      pageSummary
      pageTitle
      path
      tags
    }
    banner: allContentfulBannerHome(
      sort: {order: ASC, fields: ordem}
      filter: {node_locale: {regex: $locale}}
    ) {
      edges {
        node {
          ordem
          texto
          corTexto
          textoDoBotao
          linkBotao
          banner {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
    carousel1: file(relativePath: {eq: "Home/Carousel/01.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    carousel2: file(relativePath: {eq: "Home/Carousel/02.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    carousel3: file(relativePath: {eq: "Home/Carousel/03.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    imageResponsabilidade: file(relativePath: {eq: "Home/ResponsabilidadeSocial/responsabilidade.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    imageSustentavel: file(relativePath: {eq: "Home/NovaIdentidade/play.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`
